import { useMsal } from "@azure/msal-react";
import { useEffect, useRef, useState } from "react";
import { GetAccessToken, HasRole, Role } from "../../utils/auth-utils";
import { scopes } from "../../authConfig";
import { salesAppointmentsService } from "../../services/sales-appointments-service";
import { SalesChannel, SalesObjection, SalesScript, SalesScripts } from "../../dto/model";
import LoadingBar from "react-top-loading-bar";
import { Header } from "../header/header";
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'
import { Button, Input, InputNumber, notification, Select, Tabs, TabsProps } from "antd";
import './script-editor.css';
import { useSalesChannelState } from "../../store/header-state";

export const ScriptEditorPage = () => {
  const { instance, inProgress, accounts } = useMsal();
  const loadingBarRef = useRef(null);
  const [scripts, setScripts] = useState<SalesScript[]>();
  const [objections, setObjections] = useState<SalesObjection[]>();
  const [activeTabKey, setActiveTabKey] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [salesChannel, setSalesChannel] = useSalesChannelState((state) => [state.salesChannel, state.setSalesChannel]);

  const loadScripts = async (salesChannel: SalesChannel) => {
    try {
      setIsLoading(true);
      setScripts(undefined);
      setObjections(undefined);
      const token = await GetAccessToken(instance, inProgress, scopes.salesAppointmentApi);
      const scriptsData = await salesAppointmentsService.getScripts(await token.accessToken!, salesChannel);
      setScripts(scriptsData.data.scripts);
      setObjections(scriptsData.data.objections);
    }
    catch (e: any) {
      notification.open({
        message: e.toString(),
        placement: 'topRight',
        type: 'error',
        duration: 9999
      });
    }
    finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadScripts(salesChannel)
  }, [salesChannel]);

  useEffect(() => {
    if (loadingBarRef.current) {
      if (isLoading) {
        (loadingBarRef.current! as any).staticStart();
      } else {
        (loadingBarRef.current! as any).complete();
      }
    }
  }, [isLoading]);

  let items: TabsProps["items"] | undefined = undefined;

  if (scripts && objections) {
    const tabs = scripts.map((s, i) => ({
      key: s.bookingStatus,
      label: s.bookingStatus,
      children: <>
        <div>
          <ReactQuill
            theme='snow'
            value={s.salesScript}
            onChange={(value: string) => {
              scripts[i].salesScript = value;
              setScripts(scripts);
            }}
          />
        </div>
      </>
    }));

    const activeObjections = objections.filter(o => !o.isDeleted);

    tabs.push({
      key: "objections",
      label: "Objections",
      children: <div>
        {
          activeObjections.map((o, i) => {
            return <div className="objection">
              <div>
                <div className="objection__header">
                  <Input
                    className="objection__text"
                    onChange={(e) => {
                      activeObjections[i].objection = e.target.value;
                      setScripts(structuredClone(scripts));
                    }}
                    value={o.objection}
                  />
                  <InputNumber
                    className="objection__sort"
                    onChange={(value) => {
                      activeObjections[i].sortOrder = value!;
                      setScripts(structuredClone(scripts));
                    }}
                    value={o.sortOrder}
                  />
                  <Button
                    danger
                    type="primary"
                    onClick={_ => {
                      const objectionToDelete = objections.filter(o => !o.isDeleted)[i];
                      if (objectionToDelete.id === 0) {
                        objections.splice(objections.indexOf(objectionToDelete), 1);
                      }
                      else {
                        objectionToDelete.isDeleted = true;
                      }
                      if (o.id === 0) {
                      }
                      setObjections(structuredClone(objections));
                    }}
                  >
                    Remove
                  </Button>
                </div>
                <ReactQuill
                  theme='snow'
                  value={o.answer}
                  onChange={(value: string) => {
                    activeObjections[i].answer = value;
                    setObjections(structuredClone(objections));
                  }}
                />
              </div>
            </div>
          })
        }
      </div>
    });

    tabs.push({
      key: "hints",
      label: "Hints",
      children: <div>
        <h3>Available placeholders</h3>
        <ul>
          <li>[SALUTATION]</li>
          <li>[CUSTOMER_FIRST_NAME]</li>
          <li>[CUSTOMER_LAST_NAME]</li>
          <li>[FULL_ADDRESS]</li>
          <li>[CITY]</li>
          <li>[USERNAME] (the full name of the booking agent)</li>
          <li>[STRASSE]</li>
          <li>[HAUSNUMMER]</li>
          <li>[STADT]</li>
          <li>[CUSTOMER_EMAIL]</li>
        </ul>
      </div>
    });

    items = tabs;
  }

  return <>
    <LoadingBar color="#3e7eff" ref={loadingBarRef} shadow={true} />
    <Header />
    <div className="container script-editor">
      <div className="sales-channel">
        <label className="sales-channel__label">Sales Channel</label>
        <Select
          defaultValue={SalesChannel.DigitalSales}
          onChange={(value: SalesChannel) => setSalesChannel(value)}
        >
          <Select.Option value={SalesChannel.DigitalSales}>Digital Sales</Select.Option>
          <Select.Option value={SalesChannel.FieldSales}>Field Sales</Select.Option>
        </Select>
      </div>

      {/*
        Force re-render of the tab control when changing the Sales Channel
        The method loadScripts sets scripts ad objections to undefined to force the refresh,
        because the ReactQuill component is buggy and does not update the state properly.
      */}
      {scripts && objections && items && <><Tabs
        items={items}
        onChange={(activeTabKey) => setActiveTabKey(activeTabKey)}
      />
        <div className="objections__btns">
          {
            activeTabKey === "objections" &&
            <Button
              type="primary"
              onClick={_ => {
                objections.push({
                  id: -1,
                  answer: "",
                  sortOrder: objections.length + 1,
                  objection: "",
                  isDeleted: false
                });
                setScripts(structuredClone(scripts));
              }}>
              Add New
            </Button>
          }
          <Button
            type="primary"
            onClick={async _ => {
              try {
                setIsLoading(true);
                const token = await GetAccessToken(instance, inProgress, scopes.salesAppointmentApi);
                const body: SalesScripts = {
                  scripts,
                  objections,
                  salesChannel
                };
                await salesAppointmentsService.postScripts(body, salesChannel, token.accessToken!);
                setIsLoading(false);
              }
              catch (e: any) {
                notification.open({
                  message: e.toString(),
                  placement: 'topRight',
                  type: 'error',
                  duration: 9999
                });
              }
            }}>Save</Button>
        </div>
      </>}
    </div>
  </>
}
