import React from "react";
import "../admin-page.css";

const AdminDisclaimer = () => {
  return (
    <div className="container">
      <h1>Please use the navigation menu on the left side.</h1>
      <h1>Additional functionality will appear in the future.</h1>
    </div>
  );
};

export default AdminDisclaimer;
