import '../../App.css';

import { Header } from '../header/header';
import TextArea from 'antd/es/input/TextArea';
import { Alert, Button, InputNumber, Result } from 'antd';
import { useEffect, useState } from 'react';
import { GetAccessToken, HasRole, Role } from '../../utils/auth-utils';
import { useMsal } from '@azure/msal-react';
import { scopes } from '../../authConfig';
import { bookingPageService } from '../../services/booking-page-service';
import { useSalesChannelState } from '../../store/header-state';
import dayjs, { Dayjs } from 'dayjs';
import { DateTimePicker } from '@mantine/dates';

const minimalSnoozeHours = 3;

export const OpportunityBulkUploadPage = () => {
  const { instance, inProgress, accounts } = useMsal();
  const [opportunities, setOpportunities] = useState<string>();
  const [opportunityCount, setOpportunityCount] = useState<number>();
  const [callCount, setCallCount] = useState<string>();
  const [error, setError] = useState<string>();
  const [result, setResult] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const salesChannel = useSalesChannelState((state) => state.salesChannel);
  const [hours, setHours] = useState<number | undefined>(minimalSnoozeHours);
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);

  useEffect(() => {
    if (opportunities) {
      setOpportunityCount(opportunities.split('\n').length);
    } else {
      setOpportunityCount(undefined);
    }
  }, [opportunities]);

  return (
    <>
      <Header />
      <div className="container">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <label className="label--title">Bulk Import Opportunities</label>
          {opportunityCount && (
            <label>Opportunity Count: {opportunityCount}</label>
          )}
        </div>
        <label className="label--h">
          Enter the Opportunity Ids to import, one per line
        </label>
        <TextArea rows={8} onChange={(e) => setOpportunities(e.target.value)} />
      </div>
      <div className="container">
        <label className="label--title">Overrides</label>
        <label className="label--h">
          Certain properties of the imported opportunities can be overwritten.
          Leave it empty to use the existing value.
        </label>
        <label className="label--h">
          To add more overrides ask a new feature to the development team.
        </label>
        <div className="field">
          <label className="field__label">Not Reached Count:</label>
          <InputNumber
            style={{ width: 150 }}
            min={0}
            stringMode
            onChange={(e: any) => setCallCount(e)}
          ></InputNumber>
        </div>
        <div className="field">
          <label
            style={{ paddingTop: 10, marginRight: 18 }}
            className="field__label"
          >
            Do Not Call Before:
          </label>
          <DateTimePicker
            placeholder="Pick date and time"
            style={{ marginTop: '10px', width: 150 }}
            valueFormat="YYYY MMM DD HH:mm"
            popoverProps={{ zIndex: 10000 }}
            //value={selectedDate.toDate()}
            minDate={dayjs().utc().add(-1, 'day').toDate()}
            timeInputProps={{ step: 900 }}
            onChange={(value) => {
              setSelectedDate(dayjs(value));
              setHours(undefined);
            }}
            renderDay={(date) => {
              const day = date.getDay();
              const isSaturday = day === 6;
              return (
                <div
                  style={{
                    color: isSaturday ? 'black' : 'inherit',
                  }}
                >
                  {date.getDate()}
                </div>
              );
            }}
          />
        </div>
      </div>
      {result && (
        <div className="container">
          <Alert message={result} type="success" />
        </div>
      )}
      {error && (
        <div className="container">
          <Alert message={error} type="error" />
        </div>
      )}
      <div className="container flex flex-end">
        <Button
          type="primary"
          loading={loading}
          disabled={opportunities === undefined || opportunities === ''}
          onClick={async (_) => {
            try {
              setLoading(true);
              const token = await GetAccessToken(
                instance,
                inProgress,
                scopes.salesAppointmentApi
              );
              const req = {
                opportunities: opportunities!.split('\n'),
                overrides: {
                  callCount: callCount ?? '',
                  doNotCallBefore: selectedDate?.toISOString() ?? null,
                },
              };
              const result = await bookingPageService.BulkUploadOpportunities(
                req,
                salesChannel,
                token.accessToken
              );
              setResult(result);
            } catch (e: any) {
              setError(e.statusText ?? e.toString());
            } finally {
              setLoading(false);
            }
          }}
        >
          Upload
        </Button>
      </div>
    </>
  );
};
