import "../../App.css";
import "./conflicts.css";

import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";

import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { useMsal } from "@azure/msal-react";
import { Button, DatePicker, Result, Skeleton, Spin, Table } from "antd";
import { SlotConflict } from "../../dto/model";
import { useEffect, useState } from "react";
import { GetAccessToken, HasRole, Role } from "../../utils/auth-utils";
import { scopes } from "../../authConfig";
import { salesAppointmentsService } from "../../services/sales-appointments-service";
import { strings } from "../../lang";
import { Header } from "../header/header";
import { useSalesChannelState } from "../../store/header-state";

const { RangePicker } = DatePicker;
type RangeValue = [Dayjs | null, Dayjs | null] | null;

export interface SlotConflictDataSource extends SlotConflict {
  key: number;
  status: string;
  result: string;
}

export const Conflicts = () => {
  const [dates, setDates] = useState<RangeValue>([
    dayjs().startOf("week"),
    dayjs().endOf("week"),
  ]);
  const [value, setValue] = useState<RangeValue>(null);

  const [slots, setSlots] = useState<SlotConflictDataSource[]>();
  const [unauthorized, setUnauthorized] = useState<boolean>(false);
  const [salesManagerNames, setSalesManagerNames] = useState<string[]>([]);
  const [filteredSlots, setFilteredSlots] =
    useState<SlotConflictDataSource[]>();
  const [isRebookingAll, setIsRebookingAll] = useState<boolean>(false);
  const [rebookingMessage, setRebookingMessage] = useState<string>();
  const salesChannel = useSalesChannelState((state) => state.salesChannel);
  const { instance, inProgress, accounts } = useMsal();

  dayjs.extend(utc);

  const disabledDate = (date: Dayjs) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && date.diff(dates[0], "days") >= 7;
    const tooEarly = dates[1] && dates[1].diff(date, "days") >= 7;
    return !!tooEarly || !!tooLate;
  };

  const onOpenChange = (open: boolean) => {
    if (open) {
      setDates([null, null]);
    }
  };

  useEffect(() => {
    GetAccessToken(instance, inProgress, scopes.salesAppointmentApi).then(
      async (token) => {
        if (!token) {
          return;
        }

        try {
          if (dates === null || dates[0] === null || dates[1] === null) return;

          const calendarData = await salesAppointmentsService.getSlotConflicts(
            dates[0],
            dates[1],
            salesChannel,
            token.accessToken,
          );

          setSlots(
            calendarData.data.map((s) => ({
              ...s,
              key: s.slotId,
              status: "",
              result: "",
            })),
          );

          const uniqueSalesManagerNames = Array.from(
            new Set(calendarData.data.map((x) => x.salesManagerName)),
          ).sort();
          setSalesManagerNames(uniqueSalesManagerNames);
        } catch (e: any) {
          if (e.status === 403) {
            setUnauthorized(true);
          } else {
            throw e.statusText ?? e.toString();
          }
        }
      },
    );
  }, [dates]);

  const rebookSlot = async (slot: SlotConflictDataSource) => {
    const token = await GetAccessToken(
      instance,
      inProgress,
      scopes.salesAppointmentApi,
    );
    if (!token) {
      return;
    }

    const slotReference = slots!.find((s) => s.slotId === slot.slotId);
    slotReference!.status = "loading";
    setSlots([...slots!]);

    const result = await salesAppointmentsService.rebookSlot(
      slot.slotId,
      salesChannel,
      token.accessToken,
    );
    if (!result.isSuccess) {
      slotReference!.status = "error";
      slotReference!.result = result.errors!.join("");
    } else {
      slotReference!.status = "success";
      slotReference!.result = result.data;
    }
    setSlots([...slots!]);
  };

  const rebookAllSlots = async () => {
    // Use either the filtered slots by the table filters or all the slots if no filter was applied.
    const slotsToProcess = filteredSlots ?? slots;

    if (!slotsToProcess) {
      return;
    }

    setIsRebookingAll(true);

    try {
      for (let i = 0; i < slotsToProcess.length; i++) {
        setRebookingMessage(`${i + 1} of ${slotsToProcess.length}`);
        const slot = slotsToProcess[i];
        await rebookSlot(slot);
      }
    } catch (e: any) {
      setRebookingMessage(e.toString());
    } finally {
      setIsRebookingAll(false);
    }
  };

  const distinctDates = slots?.reduce((dates: string[], record: any) => {
    const date = dayjs(record.slotDate + "Z")
      .utc()
      .local()
      .format("DD.MM.YYYY HH:mm");
    if (!dates.includes(date)) {
      dates.push(date);
    }
    return dates;
  }, []);


  const columns = [
    {
      dataIndex: "slotUrl",
      title: "Slot Url",
      render: (_: string, record: SlotConflictDataSource) => (
        <a href={record.slotUrl} target="blank">
          Salesforce Link
        </a>
      ),
    },
    {
      dataIndex: "slotDate",
      title: strings.date,
      render: (date: string) =>
        dayjs(date + "Z")
          .utc()
          .local()
          .format("DD.MM.YYYY HH:mm"),
      filters: distinctDates?.map((date) => ({
        text: date,
        value: date,
      })),
      onFilter: (value: any, record: any) => {
        return dayjs(record.slotDate + "Z")
          .utc()
          .local()
          .format("DD.MM.YYYY HH:mm") === value;
      },
    },
    {
      dataIndex: "salesManagerName",
      title: strings.salesManager,
      filters: salesManagerNames.map((x) => ({
        text: x,
        value: x,
      })),
      onFilter: (value: any, record: any) => {
        return record.salesManagerName === value;
      },
    },
    { dataIndex: "salesManagerTeamName", title: strings.team },
    { dataIndex: "conflictingSlotSubject", title: strings.time },
    { dataIndex: "slotTier", title: strings.customerTier },
    { dataIndex: "result", title: strings.result },
    {
      dataIndex: "status",
      title: strings.status,
      render: (_: string, record: SlotConflictDataSource) => {
        switch (record.status) {
          case "loading":
            return <Spin />;
          case "error":
            return <CloseCircleOutlined style={{ color: "red" }} />;
          case "success":
            return <CheckCircleOutlined style={{ color: "green" }} />;
        }
      },
    },
    {
      dataIndex: "button",
      title: strings.rebook,
      render: (_: string, record: SlotConflictDataSource) => {
        return (
          <Button
            disabled={record.status !== ""}
            onClick={(_) => rebookSlot(record)}
            icon={<SyncOutlined />}
          />
        );
      },
    },
  ];

  return (
    <>
      <Header />
      <div className="container container--large conflicts-header">
        <div className="field">
          <label className="field__label">Date:</label>
          <RangePicker
            format={"DD.MM.YYYY"}
            allowClear={false}
            defaultValue={[dayjs().startOf("week"), dayjs().endOf("week")]}
            value={dates || value}
            disabledDate={(e) => disabledDate(e as Dayjs)}
            onCalendarChange={(val) => {
              setDates(val as RangeValue);
            }}
            onOpenChange={onOpenChange}
            onChange={(val) => {
              setValue(val as RangeValue);
            }}
          />
        </div>
        <div className="conflicts__rebooking">
          {rebookingMessage && (
            <div className="conflicts__rebooking-counter">
              {rebookingMessage}
            </div>
          )}
          <Button
            type="primary"
            onClick={rebookAllSlots}
            loading={isRebookingAll}
          >
            Rebook All
          </Button>
        </div>
      </div>
      <div className="container container--large">
        {unauthorized && (
          <Result status="warning" title={strings.UnauthorizedAccess} />
        )}
        {!slots && !unauthorized && <Skeleton />}
        {slots && (
          <Table
            size="small"
            columns={columns}
            pagination={false}
            sticky={true}
            onChange={(pagination, filters, sorter, extra) => {
              // When a filter is applied this method writes the filtered dataset into a state variable.
              // When the user clicks on "Rebook All" then we reprocess only the filtered slots (if a filter was applied).
              setFilteredSlots(extra.currentDataSource);
            }}
            dataSource={slots.map((data, index) => ({
              ...data,
              conflictingSlotSubject:
                data.conflictingSlotSubject === ""
                  ? "1 Days"
                  : data.conflictingSlotSubject,
              key: index,
            }))}
          />
        )}
      </div>
    </>
  );
};
