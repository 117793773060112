import "./online-booking-page.css";
import { AudioOutlined } from '@ant-design/icons';
import { CSSProperties, useEffect, useState } from "react";
import { strings } from "../../../lang";
import {
  CaseInfo
} from "../../../dto/model";

type OnlineRecordingBookingPageProps = {
  caseInfo: CaseInfo | null;
  isAgentOnline: boolean;
  isUserRecorded: boolean;
};


export const OnlineRecordingBookingPage = ({
  caseInfo, isAgentOnline, isUserRecorded
}: OnlineRecordingBookingPageProps) => {
  const [isRecording, setRecording] = useState<boolean>(false);
  const [time, setTime] = useState(0);


  useEffect(() => {
    if (isUserRecorded && isAgentOnline) {
      setRecording(true);
      setTime(0);
    } else {
      setRecording(false);
      setTime(0);
    }
  }, [isAgentOnline]);

  useEffect(() => {
    setTime(0);
  }, [caseInfo?.caseId]);

  // just a timer. used to display the time in the UI
  useEffect(() => {
    let timer: number | null = null;
    if (isRecording) {
      timer = window.setInterval(() => {
        setTime(prevTime => prevTime + 1);
      }, 1000);
    } else if (!isRecording && time !== 0) {
      clearInterval(timer!);
    }

    return () => clearInterval(timer!);
  }, [isRecording, time]);

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
    <div className={`bp-section-recording ${isRecording && isUserRecorded && isAgentOnline ? '' : 'bp-section-recording-hidden'}`}>
      <AudioOutlined className={`bp-microphone-${isRecording && isUserRecorded && isAgentOnline ? 'red' : 'black'}`} />
      <span className="bp-recording-time">{formatTime(time)}</span>
      <span className="bp-recording-text">{isRecording && isUserRecorded && isAgentOnline ? strings.recordingInProgress : ''}</span>
    </div>
  )
};