import { DatabaseOutlined, DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, MenuProps, Space } from "antd";
import { useEffect } from 'react';
import { strings } from '../../../lang';
import { useMsal } from "@azure/msal-react";
import { useSalesChannelState } from "../../../store/header-state";
import { HasRole, Role } from "../../../utils/auth-utils";
import { SalesChannel } from "../../../dto/model";

const items: MenuProps['items'] = [
  {
    label: 'PV Digital Sales',
    key: SalesChannel.DigitalSales,
    icon: <DatabaseOutlined />,
  },
  {
    label: 'CRM Special Channel',
    key: SalesChannel.CrmSales,
    icon: <DatabaseOutlined />,
  },
  {
    label: 'PV Field Sales',
    key: SalesChannel.FieldSales,
    icon: <DatabaseOutlined />,
  },
];

const SalesChannelDropdown = () => {
  const [salesChannel, salesChannelLabel, setSalesChannel] = useSalesChannelState((state) => [state.salesChannel, state.salesChannelLabel, state.setSalesChannel]);

  const handleMenuClick = (e: any) => {

    const selected = items.find(item => item?.key === e.key);
    if (!selected) {
      return;
    }
    setSalesChannel(selected.key! as SalesChannel);
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <div >
      <p>{strings.salesChannelDropdownLabel}</p>
      <Dropdown menu={menuProps}>
        <Button>
          <Space>
            <DatabaseOutlined />{salesChannelLabel}
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
    </div>
  );
};

export default SalesChannelDropdown;