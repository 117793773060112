import './online-booking-page-cust-info.css';
import { Slot, CaseInfo } from '../../../dto/model';
import { Button, Tag, Tooltip, Typography, message } from 'antd';
import {
  CalendarOutlined,
  CarryOutOutlined,
  CompassOutlined,
  EditOutlined,
  EnvironmentOutlined,
  IdcardOutlined,
  MailOutlined,
  PhoneOutlined,
  ShareAltOutlined,
  UserOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { strings } from '../../../lang';
import Paragraph from 'antd/es/typography/Paragraph';
import dayjs, { Dayjs } from 'dayjs';
const rootUrl = (process as any).env.REACT_APP_SALESFORCE_BASE_URL;

type CustomerInfoSectionProps = {
  caseInfo: CaseInfo;
  cancelledSlot: Slot | null;
  showWrongPhoneNumberModal: (val: boolean) => void;
  inboundCenter?: boolean | undefined;
};

const CustomerInfoSection = ({
  caseInfo,
  cancelledSlot,
  showWrongPhoneNumberModal,
  inboundCenter,
}: CustomerInfoSectionProps) => {
  //For now we forward a user to salesforce when they want to edit the customer info
  const openSalesforceCustomerLink = () => {
    window.open(`${rootUrl}/lightning/r/Contact/${caseInfo.contactId}/view`);
  };

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(
        `${rootUrl}/lightning/r/Opportunity/${caseInfo.opportunityId}/view`
      )
      .then(() => {
        message.success(strings.customerInfoCaseCopied);
      })
      .catch(() => {
        message.error(strings.customerInfoFailedToCopy);
      });
  };

  const shouldDisplayVoiceMessageHint =
    caseInfo.callCounter === 0 || caseInfo.callCounter === 6;

  const displayLastScheduledCall =
    caseInfo.bookingStatus === 'ReschedulingNeeded';

  const calculateHowLongCustomerHasBeenAdded = (creationDate: Date | null) => {
    const createdDate = creationDate ? new Date(creationDate) : null;
    if (createdDate) {
      const today = new Date();

      const timeDiff = Math.abs(today.getTime() - createdDate.getTime());
      const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

      if (daysDiff <= 1) {
        return strings.signedupPeriod.today;
      } else if (daysDiff <= 2) {
        return strings.signedupPeriod.yesterday;
      } else if (daysDiff <= 7) {
        return strings.signedupPeriod.daysAgo.replace(
          '{0}',
          daysDiff.toString()
        );
      } else if (daysDiff <= 30) {
        return strings.signedupPeriod.weeksAgo.replace(
          '{0}',
          Math.floor(daysDiff / 7).toString()
        );
      } else if (daysDiff <= 90) {
        return strings.signedupPeriod.monthsAgo.replace(
          '{0}',
          Math.floor(daysDiff / 30).toString()
        );
      } else {
        return strings.signedupPeriod.moreThan3MonthsAgo;
      }
    }
  };

  const handleWrongNumberClick = () => {
    showWrongPhoneNumberModal(true);
  };

  return (
    <>
      {/* <h4 className="bp-customer-title">
        Sales Channel: {caseInfo.salesChannel === SalesChannel.FieldSales ? "Field Sales" : "Inside Sales"}
      </h4> */}
      <section className="bp-section bp-comment bp-customer-section ">
        {/* First row */}
        <div
          className="bp-customer_icon_container bp-customer-title"
          style={{ gridArea: '1 / 1 / 2 / 2' }}
        >
          <UserOutlined /> {caseInfo.salutation} {caseInfo?.firstName}{' '}
          {caseInfo?.lastName}
        </div>

        <div
          className="bp-customer_icon_container bp-customer-title"
          style={{ gridArea: '1 / 2 / 2 / 3' }}
        >
          <a href={`tel:${caseInfo?.phone}`}>
            <PhoneOutlined /> {caseInfo?.phone}
          </a>
          <Tooltip title={strings.wrongPhoneNumber.title}>
            <Button
              disabled={inboundCenter}
              className="bp-wrong-phone-number"
              onClick={handleWrongNumberClick}
            >
              <WarningOutlined />
            </Button>
          </Tooltip>
        </div>

        <div
          className="bp-customer-section__buttons"
          style={{ gridArea: '1 / 3 / 2 / 4' }}
        >
          <Button onClick={openSalesforceCustomerLink} icon={<EditOutlined />}>
            {strings.edit}
          </Button>
          <Button
            className="bp-customer-section-copy-btn"
            icon={<ShareAltOutlined />}
            onClick={copyToClipboard}
          >
            {strings.share}
          </Button>
        </div>

        {/* Second row */}
        <div
          className="bp-customer_icon_container"
          style={{ gridArea: '2 / 1 / 2 / 2' }}
        >
          <CalendarOutlined />
          {calculateHowLongCustomerHasBeenAdded(caseInfo?.createdDate)}
        </div>

        <div
          className="bp-customer_icon_container"
          style={{ gridArea: '2 / 2 / 3 / 4' }}
        >
          <MailOutlined /> <span>{caseInfo?.email}</span>
        </div>

        {/* Third row */}
        <div
          className="bp-customer_icon_container"
          style={{ gridArea: '3 / 1 / 3 / 2' }}
        >
          <CarryOutOutlined /> {strings.bookingStatus(caseInfo?.bookingStatus)}
        </div>

        <div
          className="bp-customer_icon_container"
          style={{ gridArea: '3 / 2 / 3 / 4' }}
        >
          <EnvironmentOutlined /> {caseInfo?.street}, {caseInfo?.zipcode}{' '}
          {caseInfo?.city}
        </div>

        {caseInfo.productType && caseInfo.referrer && (
          <div style={{ gridArea: '4 / 1 / 5 / 2' }}>
            {caseInfo.productType && (
              <Tooltip title={strings.toolTipProductType}>
                <Tag color="blue" icon={<CompassOutlined />}>
                  {caseInfo.productType}
                </Tag>
              </Tooltip>
            )}
            {caseInfo.referrer && (
              <Tooltip title={strings.toolTipReferrer}>
                <Tag color="green" icon={<IdcardOutlined />}>
                  Seller: {caseInfo.referrer}
                </Tag>
              </Tooltip>
            )}
          </div>
        )}
        {cancelledSlot && displayLastScheduledCall && (
          <div className="bp-customer-last-app">
            <div style={{ margin: '5px 21px 0', fontStyle: 'italic' }}>
              {strings.lastAppointment(
                dayjs(cancelledSlot.startDate).format('D MMM YYYY'),
                dayjs(cancelledSlot.startDate).format('h:mm A'),
                cancelledSlot.salesManagerName
              )}
            </div>
          </div>
        )}
        {shouldDisplayVoiceMessageHint && (
          <div style={{ gridArea: '5 / 1 / 5 / 5', marginRight: 10 }}>
            <Typography>
              <Paragraph className="bp-customer-section-vm-hint">
                <pre style={{ margin: '0' }}>{strings.voiceMailHint}</pre>
              </Paragraph>
            </Typography>
          </div>
        )}
      </section>
    </>
  );
};

export default CustomerInfoSection;
