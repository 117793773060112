import { create } from "zustand";
import { SalesChannel } from "../dto/model";

type HeaderState = {
  language: string;
  setLanguage: (language: string) => void;
};
export const useHeaderState = create<HeaderState>()((set) => ({
  language: "",
  setLanguage: (language) => set((state) => ({ language: language })),
}));

type SalesChannelState = {
  salesChannel: SalesChannel;
  salesChannelLabel: string;
  setSalesChannel: (salesChannel: SalesChannel) => void;
};

export const useSalesChannelState = create<SalesChannelState>((set) => ({
  salesChannel: SalesChannel.DigitalSales,
  salesChannelLabel: 'PV Digital Sales',
  setSalesChannel: (salesChannel) => set((state) => ({
    salesChannel: salesChannel,
    salesChannelLabel: salesChannel === SalesChannel.DigitalSales
      ? 'PV Digital Sales'
      : salesChannel === SalesChannel.CrmSales
        ? 'CRM Special Channel'
        : 'PV Field Sales'
  })),
}));