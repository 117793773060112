import './confirmation-page.css';
import { Alert, Button } from 'antd';
import { CaseInfo, Slot } from '../../../dto/model';
import { strings } from '../../../lang';
import CustomerInfoSection from './online-booking-page-cust-info';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import CaseCommentSection from './online-booking-page-case-comments';

export interface ConfirmationPageProps {
  caseInfo: CaseInfo;
  bookedSlot: Slot;
  cancelledSlot: Slot | null;
  inputCaseComments: string;
  setInputCaseComments: (inputComments: string) => void;
  onOpenNextCase: (caseComment: string) => void;
  inboundCenter?: boolean | undefined;
  onBackToInbound?: (isSuccess: boolean) => void;
}

export const ConfirmationPage = ({
  caseInfo,
  bookedSlot,
  cancelledSlot,
  inputCaseComments,
  setInputCaseComments,
  onOpenNextCase,
  inboundCenter,
  onBackToInbound,
}: ConfirmationPageProps) => {
  const [counter, setCounter] = useState<number>(180);

  useEffect(() => {
    if (inboundCenter) {
      setCounter(-1);
    } else {
      setCounter(180);
    }
  }, [caseInfo]);

  useEffect(() => {
    if (counter === 0) {
      onOpenNextCase(inputCaseComments);
    } else if (counter < 0) {
      return;
    } else {
      const timer = setTimeout(() => setCounter(counter - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [counter]);

  const onOpenNextCaseClicked = () => {
    setCounter(-1);
    onOpenNextCase(inputCaseComments);
  };

  const cancelCountdown = () => {
    setCounter(-1);
  };

  const formatTime = (totalSeconds: number) => {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(
      2,
      '0'
    )}`;
  };

  return (
    <div className="cp-booking-info">
      <section className="cp-section">
        <Alert
          type="success"
          showIcon={true}
          message={strings.bookingSuccessful}
        />
      </section>

      <section className="cp-section">
        <CustomerInfoSection
          inboundCenter={inboundCenter}
          caseInfo={caseInfo}
          cancelledSlot={cancelledSlot}
          showWrongPhoneNumberModal={(val: boolean) => {}}
        />
      </section>

      <section className="cp-section">
        <CaseCommentSection
          caseComments={caseInfo.caseComments}
          inputCaseComment={inputCaseComments}
          onInputCaseCommentChange={setInputCaseComments}
          shouldShowPreviousComments={true}
        />
      </section>

      <section className="cp-section">
        <h4 className="confirmation-page__title">
          {strings.appointmentDetails}
        </h4>
        <ul>
          <li>
            {strings.salesManager}: <b>{bookedSlot?.salesManagerName}</b>
          </li>
          <li>
            {strings.date}:{' '}
            <b>{dayjs(bookedSlot?.startDate).format('DD.MM.YYYY')}</b>
          </li>
          <li>
            {strings.time}:{' '}
            <b>{dayjs(bookedSlot?.startDate).format('HH:mm')}</b>
          </li>
        </ul>
        <div className="confirmation-page__buttons">
          {counter >= 0 && (
            <span>
              {' '}
              {strings.formatString(
                strings.openingNextCaseIn,
                formatTime(counter)
              )}
            </span>
          )}
          {!inboundCenter && (
            <Button onClick={cancelCountdown}>{strings.cancelCountdown}</Button>
          )}
          {!inboundCenter && (
            <Button type="primary" onClick={onOpenNextCaseClicked}>
              {strings.openNextCase}
            </Button>
          )}
          {inboundCenter && (
            <Button
              type="primary"
              onClick={() => {
                onBackToInbound!(true);
              }}
            >
              {strings.backToInboundCenter}
            </Button>
          )}
        </div>
      </section>
    </div>
  );
};
