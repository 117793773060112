import './online-booking-page-case-comments.css';
import { strings } from "../../../lang";
import { CaseComments } from "../../../dto/model";
import { List, Modal } from 'antd';
import { useEffect, useState } from "react";
import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";

type CaseCommentSectionProps = {
  caseComments: CaseComments[]
  inputCaseComment: string;
  onInputCaseCommentChange: (inputCaseComment: string) => void;
  shouldShowPreviousComments: boolean;
};

const CaseCommentSection = ({ caseComments, inputCaseComment, onInputCaseCommentChange, shouldShowPreviousComments }: CaseCommentSectionProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <section
        className="bp-section bp-comment"
        style={{ flexDirection: "column" }}
      >
        <TextArea
          value={inputCaseComment}
          onChange={(e) => onInputCaseCommentChange(e.target.value)}
          rows={3}
          placeholder={strings.opportunityCommentsPlaceholder}
        />
        <div
          style={{
            marginTop: '10px',
            textAlign: 'center',
            textDecoration: 'underline',
            color: 'rgba(0, 0, 0, 0.5)',
            cursor: 'pointer'
          }}
          onClick={showModal}
        >
          {shouldShowPreviousComments && strings.caseCommentsBtn(caseComments.length)}
        </div>
      </section>
      <Modal
        title={strings.caseCommentsModalTitle}
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}>
        <List
          itemLayout="horizontal"
          dataSource={caseComments}
          renderItem={comment => (
            <List.Item>
              <List.Item.Meta
                title={comment.createdBy}
                description={<div className="description-text">{comment.body}</div>}
              />
              <div>{dayjs(comment.createdOn).format('DD MMM YYYY, HH:mm')}</div>
            </List.Item>
          )}
        />
      </Modal>
    </>
  )
}

export default CaseCommentSection;
