import './case-list-page.css';
import { useEffect, useState } from "react";
import { Header } from "../header/header";
import { CallListItem } from "../../dto/model";
import { useMsal } from "@azure/msal-react";
import { scopes } from "../../authConfig";
import { GetAccessToken } from "../../utils/auth-utils";
import { bookingPageService } from "../../services/booking-page-service";
import { Button, Skeleton, Table, Statistic } from "antd";
import { useSalesChannelState } from '../../store/header-state';

export const CaseListPage = () => {
  const { instance, inProgress, accounts } = useMsal();
  const [callList, setCallList] = useState<CallListItem[]>();
  const [isLoading, setIsLoading] = useState(false);
  const salesChannel = useSalesChannelState((state) => state.salesChannel);

  useEffect(() => {
    (async () => {
      const token = await GetAccessToken(
        instance,
        inProgress,
        scopes.salesAppointmentApi,
      );
      const cases = await bookingPageService.getAssignableCases(
        token!.accessToken,
      );
      setCallList(cases);
    })();
  }, []);

  const columns = [
    { dataIndex: "opportunityId", title: "Opportunity Id" },
    { dataIndex: "bookingStatus", title: "Booking Status" },
    { dataIndex: "callCounter", title: "Call Count" },
    { dataIndex: "notReachedCounter", title: "Not Reached Count" },
    { dataIndex: "doNotCallBefore", title: "Do Not Call Before" },
  ];

  function convertToCSV(objArray: any[]) {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = `${Object.keys(array[0]).map(value => `"${value}"`).join(",")}\r\n`;
    return array.reduce((str: any, next: any) => {
      str += `${Object.values(next).map(value => `"${value}"`).join(",")}\r\n`;
      return str;
    }, str);
  }

  const handleDownloadAll = async () => {
    setIsLoading(true);
    const token = await GetAccessToken(
      instance,
      inProgress,
      scopes.salesAppointmentApi,
    );

    const allCases = await bookingPageService.getAllCases(
      salesChannel,
      token!.accessToken,
    );

    const csvData = convertToCSV(allCases);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'opportunity_queue_bookingos.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setIsLoading(false);
  }

  return (
    <>
      <Header />
      <div className="container container--large">
        <div className="clp-horizontal-div">
          <Statistic title="Number of Callable Opportunities" value={callList ? callList[0].totalCount : 0} valueStyle={{ fontSize: 16 }} style={{ alignSelf: 'center', margin: 5 }} />
          <Button className="clp-download-btn" onClick={handleDownloadAll} loading={isLoading}>Download All</Button>
        </div>
        {!callList && <Skeleton />}
        {callList && (
          <Table
            columns={columns}
            dataSource={callList.map((data, index) => ({
              ...data,
              key: data.opportunityId,
            }))}
          />
        )}
      </div>
    </>
  );
};
