import './reached-not-booked.css';
import { strings } from '../../../lang';
import dayjs, { Dayjs } from 'dayjs';
import { useState } from 'react';
import { DateTimePicker } from '@mantine/dates';
import { Input, Modal, Radio, RadioChangeEvent } from 'antd';

export interface ReachedButNotBookedInfo {
  dateToCall: Dayjs;
}

export interface ReachedButNotBookedModalProps {
  open: boolean;
  onOk: (reachedButNotBookedInfo: ReachedButNotBookedInfo) => Promise<void>;
  onCancel: () => void;
  inboundCenter?: boolean | undefined;
}

const minimalSnoozeHours = 3;

export const ReachedButNotBookedBody = ({
  open,
  onOk,
  onCancel,
  inboundCenter,
}: ReachedButNotBookedModalProps) => {
  const [hours, setHours] = useState<number | undefined>(minimalSnoozeHours);
  const [selectedDate, setSelectedDate] = useState<Dayjs>(
    dayjs().add(hours!, 'hour')
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selected, setSelected] = useState<number>(1);
  const onOkPressed = async () => {
    try {
      setIsLoading(true);
      await onOk({ dateToCall: selectedDate });
      cleanState();
    } finally {
      setIsLoading(false);
    }
  };

  const onCancelPressed = () => {
    onCancel();
    cleanState();
  };

  const cleanState = () => {
    setHours(minimalSnoozeHours);
    setSelectedDate(dayjs().add(minimalSnoozeHours, 'hour'));
  };

  return (
    <Modal
      title={strings.modalTitleReachedNotBooked}
      centered
      closable={false}
      destroyOnClose={true}
      maskClosable={false}
      keyboard={false}
      open={open}
      onOk={() => onOkPressed()}
      okText={
        inboundCenter
          ? strings.backToInboundCenter
          : strings.saveAndOpenNextCase
      }
      cancelText={strings.cancel}
      onCancel={onCancelPressed}
      okButtonProps={{ disabled: isLoading, loading: isLoading }}
      cancelButtonProps={{ disabled: isLoading }}
    >
      <Radio.Group
        onChange={(e: RadioChangeEvent) => setSelected(e.target.value)}
        value={selected}
        className="reached-not-booked__radio-group"
      >
        <div className="reached-not-booked-label">
          {strings.modalTextReachedNotBookedHoursInAdvance}
        </div>
        <div>
          <Radio value={1} />
          <Input
            disabled={selected !== 1}
            style={{ marginTop: '10px', width: '50%', marginBottom: '10px' }}
            type="number"
            min="1"
            step="1"
            value={hours}
            onChange={(e) => {
              const number = parseInt(e.target.value, 10);
              if (isNaN(number) || number < 0) {
                cleanState();
              } else {
                setHours(number);
                setSelectedDate(dayjs().add(number, 'hour'));
              }
            }}
          />
        </div>

        <span className="reached-not-booked-label">
          {strings.modalTextSelectNewTime}{' '}
        </span>
        <div style={{ display: 'flex' }}>
          <Radio value={2} />
          <DateTimePicker
            disabled={selected !== 2}
            placeholder="Pick date and time"
            style={{ marginTop: '10px', width: '50%' }}
            valueFormat="YYYY MMM DD HH:mm"
            popoverProps={{ zIndex: 10000 }}
            value={selectedDate.toDate()}
            minDate={dayjs().toDate()}
            timeInputProps={{ step: 900 }}
            onChange={(value) => {
              setSelectedDate(dayjs(value));
              setHours(undefined);
            }}
            renderDay={(date) => {
              const day = date.getDay();
              const isSaturday = day === 6;
              return (
                <div
                  style={{
                    color: isSaturday ? 'black' : 'inherit',
                  }}
                >
                  {date.getDate()}
                </div>
              );
            }}
          />
        </div>
      </Radio.Group>
    </Modal>
  );
};
