import "./offline-booking-page.css";
import { Divider, Empty } from "antd";
import { strings } from "../../../lang";

export const OfflineBookingPage = () => {
  return (
    <div className="offline-page">
      <Empty
        description={
          <div className="offline-page-content">
            <h3>{strings.youAreOffline}</h3>
            <p>{strings.goBackOnlineToReceiveCase}</p>
          </div>
        }
      />
    </div>
  );
};