import "./admin-page.css";
import "./admin-page.css";

import { Header } from "../header/header";
import { Menu } from "antd";
import { useState } from "react";
import AdminDisclaimer from "./components/admin-disclaimer";
import AdminTranscription from "./components/admin-transcription";
import AdminBookingOS from "./components/admin-bookingos";
import type { MenuProps } from "antd";
import AdminConfiguration from "./components/admin-configuration";

type MenuItem = Required<MenuProps>["items"][number];

export const AdminPage = () => {
  const [selectedKey, setSelectedKey] = useState<string>("1");

  const items: MenuItem[] = [
    { key: 1, label: "Start" },
    { key: 2, label: "Transcription" },
    {
      key: 3,
      label: "Booking OS",
      children: [
        { key: 30, label: "Utilities" },
        { key: 31, label: "Configuration" },
      ],
    },
  ];

  return (
    <>
      <Header />
      <div className="flex-admin-container">
        <Menu
          defaultSelectedKeys={["1"]}
          mode="inline"
          style={{ width: "11%" }}
          onSelect={(item) => {
            setSelectedKey(item.key.toString());
          }}
          items={items}
        ></Menu>
        {selectedKey === "1" && <AdminDisclaimer />}
        {selectedKey === "2" && <AdminTranscription />}
        {selectedKey === "30" && <AdminBookingOS />}
        {selectedKey === "31" && <AdminConfiguration />}
      </div>
    </>
  );
};
