import { Tabs, TabsProps } from "antd";
import { SalesObjection } from "../../../dto/model";
import { useEffect, useLayoutEffect, useState } from "react";
import { strings } from "../../../lang";
import { CloseOutlined } from "@ant-design/icons";

type CaseScriptProps = {
  salesScript: string;
  salesObjections: SalesObjection[];
  resetPosition: boolean;
};

const CaseScript = ({ salesScript, salesObjections, resetPosition }: CaseScriptProps) => {

  const [selectedObjection, setSelectedObjection] = useState<SalesObjection>();

  useLayoutEffect(() => {
    if (resetPosition) {
      document.getElementsByClassName("bp-sales-script")[0].scrollTo({ top: 0, left: 0 });
    }
  }, [resetPosition])

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: strings.salesScript,
      children: (
        <div
          className="bp-sales-script"
          dangerouslySetInnerHTML={{ __html: salesScript }}
        />
      ),
    },
    {
      key: "2",
      label: strings.objections,
      children: (
        <div className="bp__objections">
          {(!selectedObjection &&
            salesObjections.map((o, index) => {
              return (
                <div
                  key={index}
                  className="bp__objection"
                  onClick={(_) => setSelectedObjection(o)}
                >
                  <div dangerouslySetInnerHTML={{ __html: o.objection }} />
                </div>
              );
            })) ||
            (selectedObjection && (
              <div className="bp__objection-answer">
                <div className="bp__objection-answer-header">
                  <div>
                    <b>{selectedObjection.objection}</b>
                  </div>
                  <div>
                    <CloseOutlined
                      onClick={(_) => setSelectedObjection(undefined)}
                    />
                  </div>
                </div>
                <p
                  dangerouslySetInnerHTML={{ __html: selectedObjection.answer }}
                />
              </div>
            ))}
        </div>
      ),
    },
  ];


  return (
    <Tabs className="bp-script" defaultActiveKey="1" items={items} />
  )
}

export default CaseScript;