import { ReactNode, useEffect, useState } from 'react';
import './header.css';
import { GetAccessToken, HasRole, Role } from '../../utils/auth-utils';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Avatar, Dropdown, Select, MenuProps, Button, Space } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { scopes } from '../../authConfig';
import { strings } from '../../lang';
import { datadogRum } from '@datadog/browser-rum';
import { useHeaderState, useSalesChannelState } from '../../store/header-state';
import Link from 'antd/es/typography/Link';

interface HeaderProps {
  optionalComponent?: ReactNode;
  centerComponents?: ReactNode;
}

export const Header = ({
  optionalComponent,
  centerComponents,
}: HeaderProps) => {
  const avatarColorList = ['#f56a00', '#7265e6', '#ffbf00', '#00a2ae'];
  const { instance, inProgress, accounts } = useMsal();
  const [accountMenuItems, setMenuItems] = useState<any>();
  const [userInitials, setUserInitials] = useState<string>();
  const [color, setColor] = useState(avatarColorList[0]);
  const isAuthenticated = useIsAuthenticated();
  const setLang = useHeaderState((state) => state.setLanguage);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isManager, setIsManager] = useState(false);
  const [isInboundAgent, setIsInboundAgent] = useState(false);

  useEffect(() => {
    if (accounts && accounts.length > 0) {
      datadogRum.setUser({
        id: accounts[0].homeAccountId,
        name: accounts[0].name,
        email: accounts[0].username,
      });
      setIsAdmin(HasRole(accounts, Role.BookingAdmin));
      setIsManager(HasRole(accounts, Role.BookingManager));
      setIsInboundAgent(HasRole(accounts, Role.InboundAgent));
    }
  }, [accounts]);

  useEffect(() => {
    if (isAuthenticated) {
      GetAccessToken(instance, inProgress, scopes.salesAppointmentApi).then(
        async (token) => {
          if (token) {
            let firstLetter = 'U';
            try {
              const initials = token.account!.name!.split(/ /g);
              firstLetter = initials[0][0];
              setUserInitials(`${initials[0][0]} ${initials[1][0]}`);

              const codePoint = firstLetter.charCodeAt(0);
              const index = codePoint % avatarColorList.length;
              setColor(avatarColorList[index]);
            } catch (e) {
              setUserInitials('U');
            }

            setMenuItems([
              {
                key: '1',
                label: token.account.name,
                disabled: true,
              },
              {
                key: '2',
                label: 'Logout',
                onClick: () => {
                  instance.logoutRedirect({
                    account: instance.getActiveAccount(),
                  });
                },
              },
            ]);
          }
        }
      );
    }
  }, [isAuthenticated]);

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: <Link href="/booking">Booking</Link>,
    },
  ];
  if (isAdmin || isInboundAgent) {
    items.push({
      key: '5',
      label: <Link href="/inbound">Inbound Center</Link>,
    });
  }
  if (isManager || isAdmin) {
    items.push(
      {
        key: '3',
        label: <Link href="/call-list">Call List</Link>,
      },
      {
        key: '7',
        label: <Link href="/conflicts">Conflicts</Link>,
      }
    );
  }
  if (isAdmin) {
    items.push(
      {
        key: '4',
        label: <Link href="/scripts">Scripts</Link>,
      },
      {
        key: '6',
        label: (
          <Link href="/bulk-import-opportunities">
            Bulk Import Opportunities
          </Link>
        ),
      },
      {
        key: '8',
        label: <Link href="/admin">Admin Page</Link>,
      }
    );
  }

  return (
    <>
      <header className="header">
        <div className="header__logo">
          <Dropdown menu={{ items }}>
            <Button icon={<MenuOutlined />} />
          </Dropdown>
          <img className="header__img" src="/logo-v2.png" alt="booking-os" />
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {centerComponents}
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {optionalComponent}
          <Select
            className="header__lang"
            defaultValue={
              ['de', 'en'].includes(strings.getLanguage())
                ? strings.getLanguage()
                : 'de'
            }
            options={[
              { value: 'de', label: 'DE' },
              { value: 'en', label: 'EN' },
            ]}
            onChange={(lang) => {
              strings.setLanguage(lang);
              setLang(lang);
            }}
          ></Select>
          <Dropdown
            className="header__avatar"
            menu={{
              items: accountMenuItems,
              selectable: true,
              defaultSelectedKeys: ['3'],
            }}
          >
            <Avatar
              className="header__avatar-icon"
              style={{ backgroundColor: color, verticalAlign: 'middle' }}
              size="large"
            >
              {userInitials}
            </Avatar>
          </Dropdown>
        </div>
      </header>
    </>
  );
};
