import { Button } from "antd";
import { useMsal } from "@azure/msal-react";
import "../admin-page.css";
import { GetAccessToken } from "../../../utils/auth-utils";
import { scopes } from "../../../authConfig";

const AdminBookingOS = () => {
  const { instance, inProgress, accounts } = useMsal();
  const rootUrl = (process as any).env.REACT_APP_API_ROOT_URL;
  const env = (process as any).env.REACT_APP_ENV;

  const handleSyncAllAsync = async () => {
    try {
      const token = await GetAccessToken(
        instance,
        inProgress,
        scopes.salesAppointmentApi
      );
      const response = await fetch(`${rootUrl}/api/v1/syncall`, {
        method: "GET",
        headers: new Headers({
          Authorization: `Bearer ${token.accessToken}`,
        }),
      });
      if (response.ok) {
        alert("Sync All has been triggered");
      } else {
        alert("Failed to trigger Sync All");
      }
    } catch (error) {
      alert("Failed to trigger Sync All");
    }
  };

  const reactivateSoftDeletedAsync = async () => {
    alert("Not implemented yet");
  };

  return (
    <>
      <div className="container flex-admin-vertical-container">
        <h1>Utilities</h1>
        <div className="flex-admin-container">
          <Button
            className="btn-admin"
            disabled={env === "prd"}
            onClick={handleSyncAllAsync}
          >
            Trigger SF Sync All
          </Button>
          <Button
            className="btn-admin"
            disabled={env === "prd"}
            onClick={reactivateSoftDeletedAsync}
          >
            Reactivate Soft Deleted
          </Button>
        </div>
      </div>
    </>
  );
};

export default AdminBookingOS;
