import Countdown, { zeroPad } from "react-countdown";
import "./online-booking-no-cases-page.css";
import { useState } from "react";
import { Empty, Spin } from "antd";
import { strings } from "../../../lang";
import { wait as waitSeconds } from "../../../services/utils";

export interface OnlineBookingNoCasesPageProps {
  onTryFetchNextCase: () => Promise<void>;
  retryCountdown: number;
}

export const OnlineBookingNoCasesPage = ({ onTryFetchNextCase, retryCountdown }: OnlineBookingNoCasesPageProps) => {
  const [isFetching, setIsFetching] = useState<boolean>(false);

  console.log('retryCountdown', retryCountdown)
  const renderer = ({ hours, minutes, seconds, completed }: any) => {
    if (completed) {
      // Render a completed state
      return <></>;
    } else {
      // Render a countdown
      return (
        <span className="header__countdown">
          <b>{seconds}</b> {strings.onlinePageNoCasesSeconds}
        </span>
      );
    }
  };

  const tryFetchCase = async () => {
    setIsFetching(true);
    await onTryFetchNextCase();
    await waitSeconds(1);
    setIsFetching(false);
  };
  return (
    <div className="center-content">
      {!isFetching && (
        <Empty description="" >
          <h3>{strings.onlinePageNoCasesTitle}</h3>
          <div>
            {strings.onlinePageNoCasesContent}
            <Countdown
              date={Date.now() + retryCountdown}
              renderer={renderer}
              onComplete={tryFetchCase}
            />
          </div>
        </Empty>
      )}
      {isFetching && (
        <>
          <Spin
            style={{ marginTop: "50px" }}
            size="large"
          />
          <h4>{strings.onlinePageNoCaesFetchingNewCase}</h4>
          <div>{strings.onlinePageNoCasesPleaseDoNotClose}</div>
        </>
      )}
    </div>
  );
};
